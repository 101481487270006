<template>
  <div class="panel_row">
    <div class="panel_col">
      <span class="panel_sub"
        ><b> {{ estimatedR }}h </b></span
      >
      <span
        class="panel_bar"
        :style="{ height: (barMaxHeight * estimated) / maxHours + 'px' }"
      ></span>
      <span class="panel_sub">estimated</span>
    </div>
    <div class="panel_col">
      <span class="panel_sub"
        >planned
        <abbr v-if="hoursTooltips && issue.planned_hours" ref="plannedHours"
          >{{ plannedR }}h</abbr
        >
        <span v-else>{{ plannedR }}h</span>
      </span>
      <span
        :class="['panel_bar', 'planned', { zero_height: !plannedBarHeight }]"
        :style="{ height: plannedBarHeight + 'px' }"
      ></span>
      <span
        :class="['panel_bar', 'passed', { full_height: !!plannedBarHeight }]"
        :style="{ height: (barMaxHeight * passed) / maxHours + 'px' }"
      ></span>
      <span class="panel_sub"
        >done
        <abbr v-if="hoursTooltips && issue.passed_hours" ref="passedHours"
          ><b>{{ passedR }}h</b></abbr
        >
        <b v-else>{{ passedR }}h</b>
      </span>
    </div>
  </div>
</template>

<script>
import tippy from "tippy.js";
import { formatK, formatTimeEntriesForIssue } from "@/views/tree/tree-helpers";

export default {
  name: "IssueEstimatedPassed",
  props: ["issue", "barMaxHeight", "hoursTooltips"],
  data() {
    return {
      previousHours: null,
    };
  },
  computed: {
    estimated() {
      return parseFloat(this.issue.agg_estimated_hours || 0);
    },
    estimatedR() {
      return formatK(Math.round(this.estimated));
    },
    passed() {
      return this.issue.agg_passed_hours;
    },
    passedR() {
      return formatK(Math.round(this.passed));
    },
    planned() {
      return this.issue.agg_planned_hours;
    },
    plannedR() {
      return formatK(Math.round(this.planned));
    },
    maxHours() {
      return Math.max(this.estimated, this.passed + this.planned);
    },
    plannedBarHeight() {
      return (this.barMaxHeight * this.planned) / this.maxHours;
    },
  },
  methods: {
    getTimeEntriesTooltipRendered(passed = 1) {
      return this.$store
        .dispatch("TimeEntry/list/getForIssue", {
          issueId: this.issue.id,
          passed,
        })
        .then(formatTimeEntriesForIssue);
    },
    tipHours() {
      const vm = this;
      if (this.$refs.passedHours) {
        tippy(this.$refs.passedHours, {
          trigger: "click",
          interactive: true,
          appendTo: document.body,
          arrow: false,
          maxWidth: "none",
          placement: "right",
          onShow(instance) {
            if (!vm.issue.passed_hours) return false;
            vm.getTimeEntriesTooltipRendered(1).then((content) => {
              instance.setContent(content);
            });
          },
        });
      }
      if (this.$refs.plannedHours) {
        tippy(this.$refs.plannedHours, {
          trigger: "click",
          interactive: true,
          appendTo: document.body,
          arrow: false,
          maxWidth: "none",
          placement: "right",
          onShow(instance) {
            if (!vm.issue.planned_hours) return false;
            vm.getTimeEntriesTooltipRendered(0).then((content) => {
              instance.setContent(content);
            });
          },
        });
      }
    },
  },
  watch: {
    issue() {
      this.$nextTick(() => {
        this.tipHours();
      });
    },
  },
  mounted() {
    if (!this.hoursTooltips) return;
    this.$nextTick(() => {
      this.tipHours();
    });
  },
};
</script>
